import React from "react"
import { Box, Link } from "rebass"
import Layout from "../../components/_layout"
import SectionHeader from "../../components/_layout/section-header"
import Divider from "../../components/divider"
import Pattern from "../../components/pattern"
import TeamBlocks from "./team-blocks"
import SEO from "../../components/seo"

const Team = () => {
  const team = [
    {
      id: 1,
      picture: "https://spinup-static-website.s3.eu-south-1.amazonaws.com/images/team/2022/antonio.jpeg",
      name: "Antonio Romano",
      role: "CEO & Founder"
    },
    {
      id: 2,
      picture: "https://spinup-static-website.s3.eu-south-1.amazonaws.com/images/team/2022/aldo.png",
      name: "Aldo Galdiero",
      role: "Head of Operation"
    },
    {
      id: 3,
      picture: "https://spinup-static-website.s3.eu-south-1.amazonaws.com/images/team/2022/armando.png",
      name: "Armando Iandolo",
      role: "Chief Marketing Operation"
    },
    {
      id: 4,
      picture: "https://spinup-static-website.s3.eu-south-1.amazonaws.com/images/team/2022/gae.png",
      name: "Gaetano Del Prete",
      role: "Chief Technology Officer"
    },
    {
      id: 5,
      picture: "https://spinup-static-website.s3.eu-south-1.amazonaws.com/images/team/2022/giovanni.png",
      name: "Giovanni Merola",
      role: "Business Development Manager"
    },
    {
      id: 6,
      picture: "https://spinup-static-website.s3.eu-south-1.amazonaws.com/images/team/2022/dario.png",
      name: "Dario Errico",
      role: "Head of Affiliation"
    },
    {
      id: 7,
      picture: "https://spinup-static-website.s3.eu-south-1.amazonaws.com/images/team/2022/fabrizio.png",
      name: "Fabrizio Vecchione",
      role: "Content Marketing Manager"
    },
    {
      id: 8,
      picture: "https://spinup-static-website.s3.eu-south-1.amazonaws.com/images/team/2022/leandro.png",
      name: "Leandro Peluso",
      role: "Sales Manager"
    },
    {
      id: 9,
      picture: "https://spinup-static-website.s3.eu-south-1.amazonaws.com/images/team/2022/massimiliano.png",
      name: "Massimiliano Maggioni",
      role: "Marketing Manager"
    },
    {
      id: 10,
      picture: "https://spinup-static-website.s3.eu-south-1.amazonaws.com/images/team/2022/marcella.png",
      name: "Marcella Esposito",
      role: "Project Manager"
    },
    {
      id: 11,
      picture: "https://spinup-static-website.s3.eu-south-1.amazonaws.com/images/team/2022/pietro.png",
      name: "Pietro Martina",
      role: "Affiliate Account Marketing"
    },
    {
      id: 12,
      picture: "https://spinup-static-website.s3.eu-south-1.amazonaws.com/images/team/2022/federica.png",
      name: "Federica Taccogna",
      role: "Finance & Administration"
    },
    {
      id: 13,
      picture: "https://spinup-static-website.s3.eu-south-1.amazonaws.com/images/team/2022/pina.png",
      name: "Giuseppina Bamundo",
      role: "Finance & Administration"
    },
    {
      id: 14,
      picture: "https://spinup-static-website.s3.eu-south-1.amazonaws.com/images/team/2022/giovanna.png",
      name: "Giovanna Pagano",
      role: "Graphic Designer"
    },
  ];

  return (
    <Layout>
      <SEO title="Team" description="The Spinuppers in full!" />
      <SectionHeader title="Team" />
      <Pattern opacity={0.2} />
      <Box bg="dark" color="white">
        <Divider
          theme="dark"
          firstLine="A young team, full of talents, guided by managers with a long-standing experience in the world of business."
          secondLine="Say hello to our Spinuppers!"
        />
      </Box>
      <TeamBlocks team={team} />
      <Divider
        firstLine="We are building the company each one of us always dreamed of working at. We nurture talent, determination and the right approach."
        secondLine={
          <>
            Do you wanna be a spinupper?{" "}
            <Link
              href="https://chats.spinup.media/c/site-contact-us"
              color="secondary"
            >
              Work with us
            </Link>
          </>
        }
      />
    </Layout>
  )
}

export default Team
